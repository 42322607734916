<template>
  <div>
    <v-card>
      <v-toolbar dense dark color="primary">
        <v-toolbar-title><h4 class="font-weight-light">LIST OF VOUCHERS FOR APPROVAL</h4>
        </v-toolbar-title>
      </v-toolbar>
      <v-layout row wrap class="align-center mx-2 mt-5">
        <v-layout col>
          <v-flex xs12 md3>
            <v-select
              class="mx-2"
              v-model="type_of_voucher"
              dense
              label="Type of Voucher"
              :items="['CV','CKV','FTV']"
              :rules="rules.combobox_rule"
              @change="selected_type"
              outlined
            ></v-select>
            <v-data-table
              dense
              :headers="headers2"
              :items="vouchered_items"
              height="600"
              disable-pagination
              hide-default-footer
            >
              <template v-slot:item="{ item }">
                <tr @click="activerow(item)"
                    :class="{ 'info white--text': item.id === selectedDepositId }">
                  <td>
                    {{ item.acro + '-' + item.voucher_no }}
                  </td>
                  <td>
                    {{ item.bank.bank_code }}
                  </td>
                  <td>
                    {{ item.cheque_no }}
                  </td>
                  <td>
                    {{ item.payee }}
                  </td>
                </tr>
              </template>
            </v-data-table>
          </v-flex>
          <v-flex xs12 md9 class="mx-2" v-if="data_items.length > 0">
            <v-card>
              <h2>Request Data | Total: P {{ total_amount }}</h2>
              <v-col cols="12" md="12">
                <v-btn class="mx-2" color="success" @click="save_request(1)"
                       v-if="!saving_data">
                  APPROVE VOUCHER
                </v-btn>
                <v-btn class="mx-2" color="error" @click="save_request(0)"
                       v-if="!saving_data">
                  DISAPPROVE VOUCHER
                </v-btn>
                <v-progress-circular
                  :size="50"
                  :width="5"
                  color="primary"
                  indeterminate
                  v-else
                ></v-progress-circular>
              </v-col>
              <v-data-table
                dense
                :headers="type_of_voucher==='CV'?headers:(type_of_voucher==='CKV'?headers3:headers4)"
                :items="data_items"
                height="600"
                disable-pagination
                hide-default-footer
              >
                <template v-slot:item="{ item }">
                  <tr v-if="type_of_voucher==='CV'">
                    <td>
                      {{ item.id }}
                    </td>
                    <td>
                      {{ item.request_id }}
                    </td>
                    <td>
                      {{ item.category.category }}
                    </td>
                    <td>
                      {{ item.branch != null ? item.branch.branch_code : '' }}
                    </td>
                    <td>
                      {{ item.particulars.particulars }}
                    </td>
                    <td>
                      {{ item.description }}
                    </td>
                    <td>
                      {{ item.credit_to }}
                    </td>
                    <td>
                      {{ item.payment_for }}
                    </td>
                    <td>
                      {{ formatPrice(item.amount) }}
                    </td>
                    <td class="text-center">
                      <div>
                        <v-icon class="mr-2" color="error" @click="remove_from_voucher(item)">
                          {{ icons.mdiDeleteForever }}
                        </v-icon>
                      </div>
                    </td>
                  </tr>
                  <tr v-if="type_of_voucher==='CKV'">
                    <td>
                      {{ item.id }}
                    </td>
                    <td>
                      {{ item.request_id }}
                    </td>
                    <td>
                      {{ item.category.category }}
                    </td>
                    <td>
                      {{ item.branch != null ? item.branch.branch_code : '' }}
                    </td>
                    <td>
                      {{ item.particulars.particulars }}
                    </td>
                    <td>
                      {{ item.description }}
                    </td>
                    <td>
                      {{ item.credit_to }}
                    </td>
                    <td>
                      {{ item.payment_for }}
                    </td>
                    <td>
                      {{ item.cheque_date }}
                    </td>
                    <td>
                      {{ item.cheque_no }}
                    </td>
                    <td>
                      {{
                        item.pdc_status === 0 ?
                          'Not Count:' + formatPrice(item.amount) : formatPrice(item.amount)
                      }}
                    </td>
                  </tr>
                  <tr v-if="type_of_voucher==='FTV'">
                    <td>
                      {{ item.id }}
                    </td>
                    <td>
                      {{ item.request_id }}
                    </td>
                    <td>
                      {{ item.ftv_from_bank.bank_code + '|' + item.ftv_from_bank.account_no }}
                    </td>
                    <td>
                      {{ item.ftv_to_bank.bank_code + '|' + item.ftv_to_bank.account_no }}
                    </td>
                    <td>
                      {{ item.description }}
                    </td>
                    <td>
                      {{ item.cheque_date }}
                    </td>
                    <td>
                      {{ item.cheque_no }}
                    </td>
                    <td>
                      {{
                        item.pdc_status === 0 ?
                          'Not Count:' + formatPrice(item.amount) : formatPrice(item.amount)
                      }}
                    </td>
                    <div>
                      <v-icon class="mr-2" color="error" @click="remove_from_voucher(item)">
                        {{ icons.mdiDeleteForever }}
                      </v-icon>
                    </div>
                  </tr>
                </template>
              </v-data-table>
            </v-card>
          </v-flex>
        </v-layout>
      </v-layout>
    </v-card>
    <snack-bar-dialog :snackbar_flag="this.snackbar" :color="this.snackbar_color"
                      :snackbar_text="this.snackbar_text"/>
  </div>
</template>

<script>
import {
  mdiAccountOutline, mdiAccountCashOutline, mdiInformationOutline, mdiPrinter
  , mdiCloudUpload
  , mdiMonitorEye
  , mdiDeleteForever
} from '@mdi/js'
import moment from 'moment'
import {mapActions, mapGetters} from 'vuex'
import snackBarDialog from '@/components/dialogs/notifications_dialog/Snackbar'
import ViewUploadCheque from '@/views/report_interface/vouchers/components/ViewUploadCheque'

const initialState = () => {
  return {
    key: 0,
    show: false,

    type_of_voucher: '',

    saving_data: false,
    printt: false,
    upload: false,
    can_upload: false,
    auto_deb: false,

    alert: false,
    alert_message: '',
    saving_data_request: false,
    alert_request: false,
    alert_message_request: '',
    total_amount: 0,
    headers: [
      {text: 'ID', value: 'date_of_deposit', sortable: false},
      {text: 'Request ID', value: 'tin', sortable: false},
      {text: 'Category', value: 'last_name', sortable: false},
      {text: 'Branch', value: 'last_name', sortable: false},
      {text: 'Particulars', value: 'last_name', sortable: false},
      {text: 'Description', value: 'last_name', sortable: false},
      {text: 'Credit To', value: 'last_name', sortable: false},
      {text: 'Payment For', value: 'last_name', sortable: false},
      {text: 'Amount', value: 'last_name', sortable: false},
      {text: 'Action', value: 'last_name', sortable: false},
    ],
    headers2: [
      {text: 'Voucher#', value: 'date_of_deposit', sortable: false},
      {text: 'Bank', value: 'last_name', sortable: false},
      {text: 'Cheque#', value: 'last_name', sortable: false},
      {text: 'Payee', value: 'tin', sortable: false},
    ],
    headers3: [
      {text: 'ID', value: 'date_of_deposit', sortable: false},
      {text: 'Request ID', value: 'tin', sortable: false},
      {text: 'Category', value: 'last_name', sortable: false},
      {text: 'Branch', value: 'last_name', sortable: false},
      {text: 'Particulars', value: 'last_name', sortable: false},
      {text: 'Description', value: 'last_name', sortable: false},
      {text: 'Credit To', value: 'last_name', sortable: false},
      {text: 'Payment For', value: 'last_name', sortable: false},
      {text: 'Schedule', value: 'last_name', sortable: false},
      {text: 'Cheque#', value: 'last_name', sortable: false},
      {text: 'Amount', value: 'last_name', sortable: false},
    ],
    headers4: [
      {text: 'ID', value: 'date_of_deposit', sortable: false},
      {text: 'Request ID', value: 'tin', sortable: false},
      {text: 'From Bank', value: 'last_name', sortable: false},
      {text: 'To Bank', value: 'last_name', sortable: false},
      {text: 'Description', value: 'last_name', sortable: false},
      {text: 'Schedule', value: 'last_name', sortable: false},
      {text: 'Cheque#', value: 'last_name', sortable: false},
      {text: 'Amount', value: 'last_name', sortable: false},
      {text: 'Action', value: 'last_name', sortable: false},
    ],
    data_items_originaldata_items_original: [],
    data_items: [],
    uploaded_data_items: [],
    data_items2: [],
    request_id: '',
    request_items: [],
    category_id: '',
    category_items: [],
    month_of_id: '',
    month_of_items: [],
    allocation_of_fund: '',
    payee: '',
    is_auto_debit: false,
    cheque_no: '',
    cheque_date: '',
    voucher_no: '',
    bank_id: '',
    bank_items: [],
    cheque_items: [],
    vouchered_items: [],
    selectedDepositId: -1,
    has_existing_voucher: false,
    existing_voucher: {},

    can_view_uploaded_cheque: false,
  }
}
export default {
  components: {
    snackBarDialog,
    ViewUploadCheque,
  },
  setup() {
    return {
      icons: {
        mdiAccountOutline,
        mdiAccountCashOutline,
        mdiInformationOutline,
        mdiPrinter,
        mdiCloudUpload,
        mdiMonitorEye,
        mdiDeleteForever,
      },
    }
  },
  data() {
    return initialState()
  },
  computed: {
    ...mapGetters('form_rules', ['rules']),
    ...mapGetters('authentication', [
      'employee_id',
      'month_of',
      'address',
      'contact',
      'name',
      'department',
      'position',
    ]),
    ...mapGetters('system_data', [
      'snackbar',
      'snackbar_color',
      'snackbar_text',
      'company_logo',
      'damayan',
      'chapel',
      'factory',
      'coop',
      'printing',
      'accounting',
      'admin',
      'amd',
      'audit',
      'book',
      'creative',
      'ecut',
      'hr',
      'it',
      'prot',
      'trea',
    ]),
  },
  methods: {
    ...mapActions('system_data', ['change_snackbar']),

    ...mapActions('cash_vouchers', ['list_of_cash_voucher_for_final_approved_report', 'final_approved_cash_voucher']),
    ...mapActions('cheque_vouchers', ['list_of_cheque_voucher_for_final_approved_report', 'final_approved_cheque_voucher']),
    ...mapActions('fund_transfer_vouchers', ['list_of_fund_transfer_voucher_for_final_approved_report', 'final_approved_fund_voucher']),

    ...mapActions('category', ['list_of_available_for_cash_voucher']),
    ...mapActions('transaction_months', ['list_of_transaction_month']),
    ...mapActions('uploaded_cheque', ['get_uploaded_cheque', 'upload_cheque']),

    ...mapActions('request_data', ['remove_request_from_voucher']),

    selected_type(value) {
      this.data_items = []
      this.data_items2 = []
      this.total_amount = '0'
      this.selectedDepositId = -1
      switch (value) {
        case "CV":
          this.list_of_cash_voucher_for_final_approved_report({
            employee_id: this.employee_id,
          })
            .then(response => {
              this.vouchered_items = response.data
            })
            .catch(error => {
              console.log(error)
            })
          break
        case "CKV":
          this.list_of_cheque_voucher_for_final_approved_report({
            employee_id: this.employee_id,
          })
            .then(response => {
              this.vouchered_items = response.data
            })
            .catch(error => {
              console.log(error)
            })
          break
        case "FTV":
          this.list_of_fund_transfer_voucher_for_final_approved_report({
            employee_id: this.employee_id,
          })
            .then(response => {
              this.vouchered_items = response.data
            })
            .catch(error => {
              console.log(error)
            })
          break
      }
    },
    date_format(value) {
      return moment(value)
    },
    formatPrice(value) {
      let val = (value / 1).toFixed(2).replace(',', '.')
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    },
    activerow(value) {
      this.data_items = value.request_data
      this.selectedDepositId = value.id
      var tto = 0
      var is_cv = this.type_of_voucher === 'CV'
      this.data_items.forEach(function (item) {
        if (is_cv) {
          tto += parseFloat(item.amount)
        } else {
          if (item.pdc_status >= 1) {
            tto += parseFloat(item.amount)
          }
        }
      })
      this.total_amount = this.formatPrice(tto)
    },
    save_request(status) {
      switch (this.type_of_voucher) {
        case "CV":
          this.final_approved_cash_voucher({
            id: this.selectedDepositId,
            status: status,
          })
            .then(() => {
              this.change_snackbar({
                show: true,
                color: 'success',
                text: 'SUCCESSFULLY APPROVED ' + this.type_of_voucher,
              })
            })
            .catch(error => {
              console.log(error)
            })
          break
        case "CKV":
          this.final_approved_cheque_voucher({
            id: this.selectedDepositId,
            status: status,
          })
            .then(() => {
              this.change_snackbar({
                show: true,
                color: 'success',
                text: 'SUCCESSFULLY APPROVED ' + this.type_of_voucher,
              })
            })
            .catch(error => {
              console.log(error)
            })
        case "FTV":
          this.final_approved_fund_voucher({
            id: this.selectedDepositId,
            status: status,
          })
            .then(() => {
              this.change_snackbar({
                show: true,
                color: 'success',
                text: 'SUCCESSFULLY APPROVED ' + this.type_of_voucher,
              })
            })
            .catch(error => {
              console.log(error)
            })
          break
      }
      this.selected_type(this.type_of_voucher)
    },
    remove_from_voucher(item) {
      if (confirm('PROCEED?')) {
        this.remove_request_from_voucher({
          request_id: item.request_id,
        })
          .then(response => {
            var color = 'success'
            if (response.status === 201) {
              color = 'error'
            }
            this.change_snackbar({
              show: true,
              color: color,
              text: response.data,
            })
            this.saving_data = false
            this.selected_type(this.type_of_voucher)
          })
          .catch(error => {
            console.log(error)
          })
      }
    }
  },
}
</script>
